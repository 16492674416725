import React from 'react';
import './App.css';
import PhotoAlbum from "react-photo-album"
import { photos_group1, photos_group2 } from "./photos";
import { logAnalytics } from "./firebase";

logAnalytics("HomePage_view")

function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        Hi, I am a <span className='App-header-bold'>street </span> and 
        <span className='App-header-bold'> travel <br /> photographer</span> capturing <br />
        <span className='App-header-bold'> human stories </span>
        through my lens.
      </header>

      <div>
        <PhotoAlbum layout="rows" photos={photos_group1} />
      </div>

      <header className="App-header">
        "Photography is the story I fail to put into words." <br /> - Destin Sparks
      </header>

      <div>
        <PhotoAlbum layout="rows" photos={photos_group2} />
      </div>
    </div>
  );
}

export default HomePage;