export const photos_group1 = [
    {src: require('./photos/DSCF6676.jpg'),  width: 472, height: 315, key: '1'},
    {src: require('./photos/DSCF6315.jpg'),  width: 473, height: 315, key: '2'},
    {src: require('./photos/DSCF4995.jpg'),  width: 472, height: 315, key: '3'},
    {src: require('./photos/DSCF6029.jpg'),  width: 315, height: 472, key: '4'},
    {src: require('./photos/DSCF0470.jpg'),  width: 472, height: 315, key: '5'},
    {src: require('./photos/DSCF5816.jpg'),  width: 473, height: 315, key: '6'},
    {src: require('./photos/DSCF4712.jpg'),  width: 315, height: 472, key: '7'},
    {src: require('./photos/DSCF5791.jpg'),  width: 472, height: 315, key: '8'},
    {src: require('./photos/DSCF0639.jpg'),  width: 315, height: 472, key: '9'},
];

export const photos_group2 = [
    {src: require('./photos/DSCF4725.jpg'),  width: 315, height: 472, key: '0'},
    {src: require('./photos/DSCF5770.jpg'),  width: 472, height: 315, key: '1'},
    {src: require('./photos/DSCF3842.jpg'),  width: 472, height: 315, key: '2'},
    {src: require('./photos/DSCF0436.jpg'),  width: 315, height: 473, key: '3'},
    {src: require('./photos/DSCF5015.jpg'),  width: 472, height: 315, key: '4'},
    {src: require('./photos/DSCF5213.jpg'),  width: 315, height: 472, key: '5'},
    {src: require('./photos/DSCF4736.jpg'),  width: 472, height: 315, key: '6'},
    {src: require('./photos/DSCF5288.jpg'),  width: 315, height: 472, key: '7'},
    {src: require('./photos/DSCF5058.jpg'),  width: 315, height: 473, key: '8'},
    {src: require('./photos/DSCF3617.jpg'),  width: 472, height: 315, key: '9'},
];
