// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkcSki0FZ457ihuLG02uCMADF0zWwgRgQ",
  authDomain: "eatenbypixels.firebaseapp.com",
  projectId: "eatenbypixels",
  storageBucket: "eatenbypixels.appspot.com",
  messagingSenderId: "386394706934",
  appId: "1:386394706934:web:a5041249cc468b8822add7",
  measurementId: "G-ZR332463RH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export function logAnalytics(key:string) {
  logEvent(analytics, key);
}