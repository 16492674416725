import React, { useEffect } from 'react';
import { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Menu } from './assets/icons/menu.svg'
import { ReactComponent as InstagramLogo } from './assets/icons/instagram-logo2.svg'
import './Navbar.css'
import PhotoProfile from './assets/icons/original_profile.jpg'
import { logAnalytics } from "./firebase";

const Navbar = (props) => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const navbarRef = useRef(null);

  useEffect(() => {
    // Add event listener to the document that listens for clicks
    document.addEventListener("click", handleClickOutside);

    // Cleanup function that removes event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    // Check if the click event did not occur within the navbar
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      // Handle the logic for closing the navbar here
      setShowNavbar(false)
    }
  }

  function handleInstaClick(event) {
    logAnalytics("Instagram_click")
  }

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="container">
        <div>
          <img className="profile-photo" src={PhotoProfile} alt='profile' />
        </div>
        <div className="logo">
            @eatenbypixels
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <div><Menu /></div>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <Link to="/" onClick={handleShowNavbar}>Home</Link>
            </li>
            <li>
              <Link to="mailto:info.eatenbypixels@gmail.com" onClick={handleShowNavbar}>Contact</Link>
            </li>
            <li>
            <Link to="https://www.instagram.com/eatenbypixels" onClick={handleInstaClick}>
              <InstagramLogo />
            </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar